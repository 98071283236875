import styled from "styled-components";

import { Column } from "../MainContent";

const SidePanelColumn = styled(Column)`
  text-align: center;
  justify-content: flex-start;

  margin-bottom: ${(props) => props.theme.spacing4x};

  @media (min-width: ${(props) => props.theme.breakLarge}) {
    text-align: left;
    padding-right: ${(props) => props.theme.spacing7x};
  }
`;

export default SidePanelColumn;
