import React, { ReactElement } from "react";

import { Box } from "@envato/design-system/components";

import MainContent, { Column, ContentSize } from "../../components/MainContent";
import Header, { HeaderProps } from "../../components/Header";
import Footer from "../../components/Footer";
import SidePanelColumn from "../../components/SidePanelColumn";

interface LayoutProps extends HeaderProps {
  size: ContentSize;
  sidePanel: ReactElement;
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({
  size,
  variant,
  sidePanel,
  children,
}) => {
  return (
    <Box
      containerType="inline-size"
      display="flex"
      flexDirection="column"
      minHeight="viewport"
    >
      <Header variant={variant} />
      <Box flexGrow="1">
        <MainContent size={size}>
          <SidePanelColumn>{sidePanel}</SidePanelColumn>
          <Column>{children}</Column>
        </MainContent>
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
