import styled from "styled-components";

import { Stack, Text, HighlightLink } from "@envato/eds";

export const Container = styled(Stack).attrs({
  spacing: ["2x", "3x", "5x"],
})`
  @media (min-width: ${(props) => props.theme.breakLarge}) {
    padding-top: ${(props) => props.theme.paddingSidePanel};
  }
`;

export const Title = styled(Text).attrs({
  variant: "title-2",
  forwardedAs: "h1",
})``;

export const Subtitle = styled(Text).attrs({ variant: "subheading" })``;

export const BodyText = styled(Text).attrs({
  variant: "body-large",
  color: "secondary",
})``;

export const Link = styled(HighlightLink)`
  margin-left: ${(props) => props.theme.spacing2x};
`;
