import React from "react";
import styled from "styled-components";

import * as Analytics from "@envato/sso-analytics";
import { Link } from "@envato/eds";
import { useConfig } from "@envato/sso-common";

import { clientDomains } from "../../config/externalUrls";
import logoMarket from "../../assets/images/logos/logo-envatomarket.svg";
import logoElements from "../../assets/images/logos/logo-envato.svg";
import logoTuts from "../../assets/images/logos/logo-tutsplus.svg";
import logoPlaceit from "../../assets/images/logos/logo-placeit.svg";

const clients = [
  {
    name: "Elements",
    image: logoElements,
    slug: "elements",
    url: clientDomains.elements,
  },
  {
    name: "Market",
    image: logoMarket,
    slug: "themeforest",
    url: clientDomains.market,
  },
  {
    name: "Tuts+",
    image: logoTuts,
    slug: "tutsplus",
    url: clientDomains.tutsplus,
  },
  {
    name: "Placeit",
    image: logoPlaceit,
    slug: "placeit",
    url: clientDomains.placeit,
  },
];

const Container = styled.ul`
  margin: 0;
  padding: 0;
  padding-top: ${(props) => props.theme.spacing2x};
  list-style: none;

  display: inline-grid;
  grid-template-columns: auto auto;
  row-gap: ${(props) => props.theme.spacing2x};

  @media (min-width: ${(props) => props.theme.breakLarge}) {
    grid-template-columns: auto;
    row-gap: ${(props) => props.theme.spacing3x};
  }
`;

const ClientItem = styled.li`
  margin: 0;
  padding: 0;
`;

const ClientLink = styled(Link)`
  justify-self: center;

  @media (min-width: ${(props) => props.theme.breakHuge}) {
    justify-self: left;
  }
`;

const ClientLogo = styled.img`
  height: ${(props) => props.theme.spacing3x};

  @media (min-width: ${(props) => props.theme.breakSmall}) {
    height: ${(props) => props.theme.spacing5x};
  }
`;

const BrandingBox = () => {
  const [{ currentUser }] = useConfig();

  return (
    <Container>
      {clients.map((c) => (
        <ClientItem key={c.slug}>
          <Analytics.Element trackingId="Branding Box" trackingLabel={c.slug}>
            <ClientLink
              to={currentUser ? `/sign_in?auto=true&to=${c.slug}` : c.url}
            >
              <ClientLogo src={c.image} alt={c.name} />
            </ClientLink>
          </Analytics.Element>
        </ClientItem>
      ))}
    </Container>
  );
};

export default BrandingBox;
